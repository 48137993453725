import React from "react";
import {Stack, Link} from "@mui/material";
import Contattami from "../contattami/Contattami";

export default function Footer(props) {
    return (
        <React.Fragment>
            <Stack direction={'column'} id='footerStack'>
                <Contattami data={props.data}/>
                <Stack direction={'column'} alignSelf={'center'}>
                    <p style={{marginTop: 30, alignSelf: 'center'}}>{props.data.footer.piva}</p>
                    <p style={{alignSelf: 'center'}}>{props.data.footer.reference}<Link
                        href={'https://www.linkedin.com/in/matteo-scaccabarozzi/'} style={{cursor: 'pointer'}}
                        color={'blue'} underline={'hover'}>Matteo Scaccabarozzi</Link></p>
                </Stack>
            </Stack>
        </React.Fragment>
    )
}

import React, {useState} from "react";
import Stack from '@mui/material/Stack';
import logo from "../../images/logo_mobile.png";
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Divider from '@mui/material/Divider';
import './NavbarMobile.css'
import {Home, Person, WhatsApp, Work, RateReview} from "@mui/icons-material";
import {NavLink} from "react-router-dom";
import {LANGUAGES, PRIMARY_COLOR, SECONDARY_COLOR} from "../../data/Constants";
import Select from "@mui/material/Select";
import {MenuItem} from "@mui/material";
import {SxProps} from "@mui/material/styles";


const drawerWidth = 240;

const styles: SxProps = {
    select: {
        '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent'
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
            color: 'white'
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent"
        },
        color: 'white',
        fontFamily: 'inherit',
        fontSize: '18px'
    }
};

export default function NavbarMobile(props) {
    const [drawerOpen, setDrawerOpen] = useState(false)

    function scroll() {
        const element = document.getElementById('footerStack');
        element.scrollIntoView({behavior: 'smooth'})
        setTimeout(handleDrawerToggle, 700)
    }


    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    function switchList(index) {
        switch (index) {
            case 0:
                return <Home style={props.button === index ? {color: PRIMARY_COLOR} : {color: 'white'}}/>
            case 1:
                return <Work style={props.button === index ? {color: PRIMARY_COLOR} : {color: 'white'}}/>
            case 4:
                return <RateReview style={props.button === index ? {color: PRIMARY_COLOR} : {color: 'white'}}/>
            case 5:
                return <WhatsApp style={props.button === index ? {color: PRIMARY_COLOR} : {color: 'white'}}/>
            default:
                return null
        }
    }

    const drawer = (
        <div style={{backgroundColor: PRIMARY_COLOR, height: '100%'}}>
            <List style={{backgroundColor: PRIMARY_COLOR}} id={'navbarMobileButton'}>
                {['homepage', 'servizi', 'educazione', 'dog_sit', 'recensioni', 'contattami'].map((text, index) => (
                    <NavLink exact
                             to={index === 2 ? '/servizi/percorso_educazione' : (index === 3 ? '/servizi/dog_sitting' : (index === 5 ? null : '/' + text.toLowerCase()))}>
                        <ListItem sx={{fontFamily: 'inherit'}} key={text} disablePadding>
                            <ListItemButton onClick={index === 5 ? scroll : null} id={'navbarMobileButton'}
                                            sx={index === props.button ? {
                                                backgroundColor: 'white',
                                                color: PRIMARY_COLOR
                                            } : null}>
                                <ListItemIcon>
                                    {switchList(index)}
                                </ListItemIcon>
                                <ListItemText disableTypography
                                              style={{fontSize: 15}}
                                              sx={index === props.button ? {color: PRIMARY_COLOR} : {color: 'white'}}
                                              primary={props.data.navbar[text]}/>
                            </ListItemButton>
                        </ListItem>
                    </NavLink>
                ))}
            </List>
            <Divider/>
            <List style={{backgroundColor: PRIMARY_COLOR}}>
                {['personal_area'].map((index, text) => (
                    <NavLink exact to={'/AreaPersonale'}>
                        <ListItem key={text} disablePadding>
                            <ListItemButton id={'navbarMobileButton'}>
                                <ListItemIcon>
                                    <Person
                                        style={props.button === 'AreaPersonale' ? {color: SECONDARY_COLOR} : {color: 'white'}}/>
                                </ListItemIcon>
                                <ListItemText sx={{color: 'white', fontSize: 15}} disableTypography primary={props.data.navbar[index]}/>
                            </ListItemButton>
                        </ListItem>
                    </NavLink>
                ))}
            </List>
            <Stack display={'flex'}>
                <Select
                    value={props.getLanguage}
                    label="Lingua"
                    sx={styles.select}
                >
                    {LANGUAGES.map(({code, label}) => (
                        <MenuItem onClick={() => props.setLanguage(code)} value={code}>{label}</MenuItem>
                    ))}
                </Select>
            </Stack>
        </div>
    );

    return (
        <React.Fragment>
            <Stack id='navbarBox' position={'fixed'} direction="row" spacing={1} justifyContent="space-between"
                   alignItems="center">

                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    id='buttonMenu'
                >
                    <MenuIcon/>
                </IconButton>

                <div id='navTextContainer'>
                    <NavLink style={{color: 'white'}} exact to={'/'}>
                        <h2 id='nameMobile'>
                            Martina Curcio
                        </h2>
                        <h4 id='jobTitleMobile'>
                            Educatrice cinofila
                        </h4>
                    </NavLink>
                </div>
                <div>
                    <img
                        alt="Logo"
                        src={logo}
                        width={60}
                        height={'100%'}
                        style={{marginRight: 15}}
                    />
                </div>
            </Stack>

            <Drawer
                variant="temporary"
                open={drawerOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: {sm: 'block'},
                    '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    fontFamily: 'inherit'
                }}
            >
                {drawer}
            </Drawer>

        </React.Fragment>
    )

}

import React from "react";
import './Servizi.css'
import education_photo from '../../images/education_photo.jpg'
import dog_walker from '../../images/dog_walker.jpg'
import Stack from "@mui/material/Stack";
import {PRIMARY_COLOR, SECONDARY_COLOR} from "../../data/Constants";

export default function Servizi_options(props) {


    return (
        <React.Fragment>
            <Stack direction={'row'} overflow={'hidden'} margin={2} flexWrap={'wrap'} justifyContent="space-around"
                   alignItems="end" gap={10}>

                <Stack flex={0.4} flexGrow={1} minHeight={200}
                       direction={'column'} textAlign={'center'} id={'service_container'}>
                    <h2 id={'title_services_home'}>{props.data.services.title_edu}</h2>
                    <p id={'subtitle_services_home'}>{props.data.services.body_edu}</p>
                    <a title={props.data.services.hint_edu}
                       href={'/servizi/percorso_educazione'}>
                        <p style={{backgroundColor: SECONDARY_COLOR + '44', color: PRIMARY_COLOR}}
                           id='scopridipiù'>{props.data.services.show_more_edu}</p>
                    </a>
                    <a title={props.data.services.hint_edu}
                       href={'/servizi/percorso_educazione'}>
                        <img src={education_photo} id={'image'} width={300} height={'100%'}
                             alt={props.data.services.alt_edu}>
                        </img>
                    </a>

                </Stack>
                <Stack flex={0.40} flexGrow={1} minHeight={200}
                       direction={'column'} textAlign={'center'} id={'service_container'}>
                    <h2 id={'title_services_home'}>{props.data.services.title_dog_sit}</h2>
                    <p id='subtitle_services_home'>{props.data.services.body_dog_sit1} <span
                        style={{fontFamily: 'Confortaa_bold'}}>{props.data.services.body_dog_sit2}</span>{props.data.services.body_dog_sit3}</p>
                    <a title={'Approfondisci il dog sitting'} href={'/servizi/dog_sitting'}>
                        <p style={{backgroundColor: SECONDARY_COLOR + '44', color: PRIMARY_COLOR}}
                           id='scopridipiù'>{props.data.services.show_more_dog_sit}</p>
                    </a>
                    <a title={props.data.services.hint_dog_sit} href={'/servizi/dog_sitting'}>
                        <img src={dog_walker} width={300} id={'image'} height={'100%'}
                             alt={props.data.services.alt_dog_sit}>
                        </img>
                    </a>
                </Stack>
            </Stack>

        </React.Fragment>
    )
}

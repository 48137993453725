import React, {useEffect} from "react";
import Navbar from "../navbar/Navbar"
import SadDog from "../../images/sad_dog.png"

export default function ErrorPage() {

    useEffect(() => {
            document.title = 'Pagina non trovata'
    }, [])

    return (
        <React.Fragment>
                <Navbar/>
            <div align={'center'} style={{fontSize:'40px', marginRight: '50px', paddingTop:'120px', textShadow:' 3px 1px 1px white'}}>
                Pagina non trovata!
                <img style={{width: '350px', maxWidth: '700px', position: 'absolute', left: 0, bottom: 0}} src={SadDog} alt='SadDog'/>
            </div>
        </React.Fragment>
    )
}

export const PRIMARY_COLOR = "#6C05AB"
export const SECONDARY_COLOR = "#F18334"
export const FONT_TITLE = "calc(20px + 0.390625vw)"
export const FONT_SUBTITLE = "calc(16px + 0.390625vw)"
export const FONT_PARAGRAPH = "calc(14px + 0.390625vw)"
export const BOLD_FONT = "Confortaa_bold"

export const LANGUAGES = [
    { label: "\uD83C\uDDEE\uD83C\uDDF9 Italiano", code: "it" },
    { label: "🇬🇧 English", code: "en" },
];
import React, {useEffect, useState} from "react";
import Navbar from '../navbar/Navbar'
import TitleHomepage from './TitleHomepage'
import Stack from '@mui/material/Stack';
import '../navbar/NavbarMobile.css'
import './homepage.css'
import Footer from "../footer/Footer";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Avatar from "@mui/material/Avatar";
import {Typography} from "@mui/material";
import {
    Timeline,
    TimelineConnector, TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from "@mui/lab";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import PetsRoundedIcon from '@mui/icons-material/PetsRounded';
import dog_1 from "../../images/dog_1.gif";
import {FONT_SUBTITLE, PRIMARY_COLOR, SECONDARY_COLOR} from "../../data/Constants";
import Divider from "@mui/material/Divider";
import Servizi_options from "../servizi/Servizi_options";
import {db, doc} from "../../firebase/Firebase";
import {getDoc} from "firebase/firestore";


export default function Homepage(props) {

    const [Reviews, setReviews] = useState([])
    const [carouselIndex, setCarouselIndex] = useState(0)
    const [arrayReviews, setArrayReviews] = useState([]);

    let showMore = []

    function itemCarousel(element, index) {
        return (
            <div id={"home_reviews"}>
                <Divider/>
                <Stack paddingTop={2} direction={'row'} margin={0}>
                    <Stack direction={'column'} margin={0}>
                        <Stack flexWrap={'wrap'} direction={'row'}
                               justifyContent="space-around">
                            <Stack flex={'15%'} direction={'column'}
                                   alignItems={'flex-start'}>

                                <Avatar
                                    alt={'Dog_picture'}
                                    src={(element.photo)}
                                    sx={{
                                        minWidth: 100,
                                        width: '10vw',
                                        minHeight: 100,
                                        height: '10vw',
                                        margin: 3,
                                        alignSelf: 'center'
                                    }}>
                                    {element.name.charAt(0).toUpperCase()}
                                </Avatar>

                            </Stack>
                            <Stack flex={'85%'} direction={'column'}>
                                <div>
                                    <h3 style={{
                                        marginTop: 0,
                                        textAlign: 'center',
                                        fontFamily: 'Confortaa_bold'
                                    }}>{element.title}</h3>
                                </div>
                                <p style={{textAlign: 'left', letterSpacing: '0.5px', marginTop: 0}}>
                                    {showMore[index] && element.body.length > 199 ? element.body.substring(0, 200) + "..." : element.body}" {showMoreComponent(element, index)}
                                </p>
                                <p style={{
                                    marginTop: 0,
                                    textAlign: 'right',
                                    fontStyle: 'italic',
                                    fontSize: FONT_SUBTITLE
                                }}>{element.name}</p>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </div>
        )
    }

    async function getReviews() {
        const userRef = doc(db, 'reviews', 'reviews_array')
        const docu = await getDoc(userRef)
        setArrayReviews(docu.data().reviews)
    }

    useEffect(() => {
        getReviews()
    }, [])

    useEffect(() => {
        showMore = new Array(arrayReviews.length).fill(true)
        if (Reviews.length === 0)
            arrayReviews.map((element, index) => {
                setReviews(prevState => [...prevState, itemCarousel(element, index)])
            })
    }, [arrayReviews])


    function showMoreBody(element, index) {
        showMore[index] = !showMore[index]
        setReviews(existingItems => {
            return existingItems.map((item, j) => {
                return j === index ? itemCarousel(element, index) : item
            })
        })
    }

    function showMoreComponent(element, index) {
        if (!showMore[index])
            return (
                <span id='mostradipiù' onClick={() => showMoreBody(element, index)}>{props.data.homepage.show_less}</span>
            )
        else
            return (
                <span id='mostradipiù' onClick={() => showMoreBody(element, index)}>{props.data.homepage.show_more}</span>
            )
    }

    function CustomizedTimeline() {
        return (
            <Timeline sx={{width: '100vw', mb: 0, left: '-15vw', position: 'relative'}}>
                <TimelineItem>
                    <TimelineOppositeContent sx={{flex: 0.15}}/>
                    <TimelineSeparator>
                        <TimelineConnector
                            sx={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20, bgcolor: 'white'}}/>
                        <TimelineDot sx={{bgcolor: PRIMARY_COLOR}}>
                            <GroupRoundedIcon/>
                        </TimelineDot>
                        <TimelineConnector
                            sx={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}/>
                    </TimelineSeparator>
                    <TimelineContent sx={{py: '2px', width: '20vw', px: 2, fontFamily: 'inherit'}}>
                        <Typography sx={{top: 4}} variant="h6" fontFamily={'inherit'} component="span">
                            {props.data.homepage.title1}
                        </Typography>
                        <Typography fontFamily={'inherit'}>{props.data.homepage.body1}</Typography>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent sx={{flex: 0.15}}/>
                    <TimelineSeparator>
                        <TimelineConnector sx={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}/>
                        <TimelineDot sx={{bgcolor: PRIMARY_COLOR}}>
                            <PetsRoundedIcon/>
                        </TimelineDot>
                        <TimelineConnector sx={{borderTopLeftRadius: 20, borderTopRightRadius: 20, bgcolor: 'white'}}/>
                    </TimelineSeparator>
                    <TimelineContent sx={{py: '12px', width: '20vw', px: 2, fontFamily: 'inherit'}}>
                        <Typography variant="h6" fontFamily={'inherit'} component="span">
                            {props.data.homepage.title2}
                        </Typography>
                        <Typography fontFamily={'inherit'}>{props.data.homepage.body2}</Typography>
                    </TimelineContent>
                </TimelineItem>
            </Timeline>
        );
    }

    return (
        <React.Fragment>
            <Navbar data={props.data} setLanguage={props.setLanguage} getLanguage={props.getLanguage} button={0}/>
            <Stack direction="column">
                <TitleHomepage getLanguage={props.getLanguage} data={props.data}/>
                <div id='thirdBlock'>
                    <Typography bgcolor={PRIMARY_COLOR + '55'} sx={{borderRadius: '15px 0px 15px 15px'}} padding={1}
                                fontFamily={'inherit'} variant='h5' align={'center'} marginTop={3}
                                marginBottom={3}>
                        {props.data.homepage.banner1}
                    </Typography>
                    <div>
                        <h4 id='subtitleThirdBlock'>
                            {props.data.homepage.subtitle1}
                        </h4>
                        {CustomizedTimeline()}
                        <Servizi_options data={props.data}/>
                    </div>
                </div>

                <img width={'100%'} height={'100%'} style={{width: 'calc(250px + 20vw)', verticalAlign: 'middle'}} src={dog_1} alt={'dog_walking'}/>

                <AliceCarousel onSlideChanged={(e) => setCarouselIndex(e.item)}
                               activeIndex={carouselIndex}
                               autoPlay={true} autoPlayStrategy={'all'} infinite={true} autoPlayInterval={15000}
                               mouseTracking={true} items={Reviews}/>
                <div style={{alignSelf: 'center'}}>
                    <a title={props.data.reviews.all_reviews_hint} href={'/recensioni'}>
                        <p style={{
                            backgroundColor: SECONDARY_COLOR + '44',
                            color: PRIMARY_COLOR,
                            textAlign: 'center'
                        }}
                           id='scopridipiù'>{props.data.reviews.all_reviews}</p>
                    </a>
                </div>
                <Footer data={props.data}/>
            </Stack>
        </React.Fragment>
    )
}

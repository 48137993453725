import React, {useEffect, useRef, useState} from "react";
import {db, doc} from "../../firebase/Firebase";
import {getDoc, setDoc, updateDoc, arrayUnion} from "firebase/firestore";
import {
    Button,
    Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Fab, MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField, CircularProgress,
} from '@mui/material';
import IconButton from "@mui/material/IconButton";
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import {BOLD_FONT} from "../../data/Constants";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import {FormHelperText} from "@mui/joy";
import {getDownloadURL, deleteObject, getStorage, ref, uploadBytes} from "firebase/storage";
import Compressor from "compressorjs";


export default function GestoreRecensioni({toast}) {

    const [arrayReviews, setArrayReviews] = useState([]);
    const [modifiedChecker, setModifiedChecker] = useState(false);
    const [backupArray, setBackupArray] = useState([]);
    const [openReview, setOpenReview] = useState([]);
    const [openAddReview, setOpenAddReview] = React.useState(false);
    const [enableSubmit, setEnableSubmit] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [photoToDelete, setPhotoToDelete] = React.useState([])
    const [editMode, setEditMode] = React.useState(false)
    const [photo, setPhoto] = React.useState()
    const [blob, setBlob] = React.useState()
    const [newReview, setNewReview] = React.useState([{
        name: "",
        body: "",
        title: "",
        service: "",
        id: ""
    }])

    useEffect(() => {
        checkSubmitNewReview()
    }, [newReview, setNewReview, blob])

    let modified = false
    const inputFile = useRef(null)
    let array = []

    function revokeURL() {
        URL.revokeObjectURL(photo)
    }

    async function getReviews() {
        const userRef = doc(db, 'reviews', 'reviews_array')
        const docu = await getDoc(userRef)
        setArrayReviews(docu.data().reviews)
        setOpenReview(Array(docu.data().reviews.length).fill(false))
    }

    const handleChange = e => {
        const {name, value} = e.target;
        setNewReview(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    async function confirmEditReview() {


        if (blob !== undefined && blob !== "") {
            const storage = getStorage();
            const fileRef = ref(storage, 'public/reviews/' + newReview.id);

            new Compressor(blob, {
                quality: 0.8,
                success: (compressedResult) => {
                    uploadBytes(fileRef, compressedResult)

                }
            })
        }

        array = [...arrayReviews.slice(0, newReview.position), newReview, ...arrayReviews.slice(newReview.position + 1)]

        const userRef = doc(db, 'reviews/reviews_array')
        await setDoc(userRef, {reviews: array}).catch(error => toast('error', error.message)).then(() => {
            toast('success', 'Recensione aggiornata!');
            setNewReview([]);
            revokeURL()
            setPhoto("")
            setBlob("")
            setOpenAddReview(false)
            setModifiedChecker(false)
            setBackupArray([])
            modified = false
            getReviews()
        })
    }

    function getPhoto(e) {
        setPhoto(URL.createObjectURL(e.target.files[0]))
        setBlob(e.target.files[0])
    }

    async function uploadReview() {

        setLoading(true)

        function randomString(length, chars) {
            let result = '';
            for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
            return result;
        }

        const randomID = randomString(16, '123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ')

        const storage = getStorage();
        const fileRef = ref(storage, 'public/reviews/' + randomID);

        new Compressor(blob, {
                quality: 0.95,
                success: (compressedResult) => {
                    uploadBytes(fileRef, compressedResult).then((snapshot) => {
                        getDownloadURL(snapshot.ref).then(async (downloadURL) => {
                            const data = {
                                name: newReview.name,
                                title: newReview.title,
                                body: newReview.body,
                                service: newReview.service,
                                id: randomID,
                                photo: downloadURL,
                                position: arrayReviews.length + 1
                            }
                            const userRef = doc(db, 'reviews/reviews_array')
                            await updateDoc(userRef, {reviews: arrayUnion(data)}).catch(error => toast('error', error.message)).then(() => {
                                toast('success', 'Recensione caricata!');
                                setNewReview([]);
                                setOpenAddReview(false)
                                revokeURL()
                                setPhoto("")
                                getReviews()
                            })
                        })
                    }).catch(error => toast('error', error.message))
                },
                error(error
                          :
                          Error
                ) {
                    toast('error', error.message)
                }
            }
        )
        ;
        setLoading(false)

    }

    async function changeOrderReviews() {
        const userRef = doc(db, 'reviews/reviews_array')
        await setDoc(userRef, {reviews: arrayReviews}).catch(error => toast('error', error.message)).then(() => {
            toast('success', 'Modifiche confermate!');
            setBackupArray([])
            modified = false;
            setModifiedChecker(false)
        }).catch(error => toast('error', error.message))
        deletePhoto()
    }

    function checkSubmitNewReview() {
        setEnableSubmit(newReview.name !== "" && newReview.title !== "" && newReview.body !== "" && photo !== "" && newReview.service !== ""
            && newReview.name !== undefined && newReview.title !== undefined && newReview.body !== undefined && photo !== undefined && newReview.service !== undefined)
    }

    function addReviewBanner() {
        return (
            <React.Fragment>
                <Dialog open={openAddReview} onClose={() => setOpenAddReview(false)}>
                    <DialogTitle
                        sx={{fontFamily: 'inherit'}}>{editMode ? 'Modifica la recensione' : 'Aggiungi una recensione'}</DialogTitle>
                    <DialogContent>
                        {/*<DialogContentText sx={{fontFamily: 'inherit'}}>
                            Riempi tutti i campi richiesti
                        </DialogContentText>*/}
                        <TextField
                            margin="dense"
                            InputLabelProps={{style: {fontFamily: 'inherit'}}}
                            InputProps={{style: {fontFamily: 'inherit'}}}
                            label="Nome del cane"
                            type="string"
                            size={'small'}
                            fullWidth
                            required
                            defaultValue={newReview.name}
                            name={'name'}
                            color={'secondary'}
                            style={{fontFamily: 'Confortaa'}}
                            variant="standard"
                            onBlur={handleChange}
                        />
                        <TextField
                            margin="dense"
                            InputLabelProps={{style: {fontFamily: 'inherit'}}}
                            InputProps={{style: {fontFamily: 'inherit'}}}
                            size={'small'}
                            required
                            name={'title'}
                            defaultValue={newReview.title}
                            onBlur={handleChange}
                            label="Titolo della recensione"
                            type="string"
                            fullWidth
                            color={'secondary'}
                            style={{fontFamily: 'inherit'}}
                            variant="standard"
                        />
                        <TextField
                            margin="dense"
                            sx={{mt: 2}}
                            InputLabelProps={{style: {fontFamily: 'inherit'}}}
                            InputProps={{style: {fontFamily: 'inherit'}}}
                            label="Recensione"
                            defaultValue={newReview.body}
                            type="string"
                            required
                            name={'body'}
                            onBlur={handleChange}
                            multiline
                            fullWidth
                            color={'secondary'}
                            variant="outlined"
                        />
                        <Select
                            labelId="servizio-offerto-label"
                            id="servizio-offerto"
                            sx={{fontFamily: 'inherit', minWidth: '150px', mt: 2}}
                            defaultValue={newReview.service}
                            color={'secondary'}
                            placeholder={'Servizio offerto'}
                            label="Servizio offerto"
                            name={'service'}
                            onBlur={handleChange}
                        >
                            <MenuItem value={'educazione'} sx={{fontFamily: 'inherit'}}>Educazione</MenuItem>
                            <MenuItem value={'dog_sitting'} sx={{fontFamily: 'inherit'}}>Dog-Sitting</MenuItem>
                        </Select>
                        <FormHelperText>Seleziona un servizio</FormHelperText>
                        <Stack direction={'row'}>
                            <Button variant={'contained'} component={'label'} sx={{
                                fontFamily: 'inherit',
                                backgroundColor: 'orange',
                                height: '40px',
                                mr: 4,
                                mt: 2,
                                '&:hover': {backgroundColor: 'chocolate'}
                            }}>
                                <input hidden onChange={e => getPhoto(e)} type={'file'} ref={inputFile}
                                       accept="image/png, image/jpeg"/>
                                Seleziona una foto</Button><Avatar sx={{mt: -5, width: 100, height: 100}}
                                                                   src={photo !== null ? photo : null}></Avatar>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={'contained'} sx={{
                            fontFamily: 'inherit',
                            backgroundColor: 'red',
                            '&:hover': {backgroundColor: 'darkred'}
                        }} onClick={() => {
                            setOpenAddReview(false);
                            setNewReview([]);
                            revokeURL()
                            setPhoto("")
                        }}>Esci</Button>
                        <Button endIcon={loading ? <CircularProgress size={20} sx={{color: 'white'}}/> : null} disabled={!enableSubmit} variant={'contained'} onClick={() => {
                            if (editMode)
                                confirmEditReview()
                            else
                                uploadReview()
                        }} sx={{
                            fontFamily: 'inherit',
                            backgroundColor: 'green',
                            '&:hover': {backgroundColor: 'darkgreen'}
                        }}>{editMode ? 'Modifica la recensione' : 'Aggiungi recensione'}</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }

    function eliminateReview(index) {

        setChangeInProgress()

        setPhotoToDelete(existingElements => [...existingElements, arrayReviews[index].id])

        setArrayReviews(existingItems => [...existingItems.slice(0, index),
            ...existingItems.slice(index + 1)])
    }

    function deletePhoto() {
        const storage = getStorage();

        photoToDelete.map(value => {
            const desertRef = ref(storage, 'public/reviews/' + value);
            deleteObject(desertRef)
        })

        setPhotoToDelete([])
    }

    function setChangeInProgress() {
        if (!modified && !modifiedChecker) {
            setBackupArray(arrayReviews.map(item => ({...item})))
            setModifiedChecker(true)
            modified = true
        }
    }

    function editReview(index) {
        setEditMode(true)
        setOpenAddReview(true)

        setPhoto(arrayReviews[index].photo)

        setNewReview({
            name: arrayReviews[index].name,
            title: arrayReviews[index].title,
            body: arrayReviews[index].body,
            photo: arrayReviews[index].photo,
            id: arrayReviews[index].id,
            position: arrayReviews[index].position,
            service: arrayReviews[index].service
        })

    }


    function move(index, option) {
        setChangeInProgress()
        array = arrayReviews

        if (option === 'UP') {
            array[index - 1].position = index
            array[index].position = index - 1
        } else {
            array[index].position = index + 1
            array[index + 1].position = index
        }


        array = array.sort((a, b) => {
            return a.position - b.position
        });

        setArrayReviews(array.map(item => ({...item})))


    }

    function redo() {
        setArrayReviews(backupArray)
        setBackupArray([])
        setModifiedChecker(false)
        setPhotoToDelete([])
        modified = false
    }

    function Row(row, index, colorRows, length) {
        let color = '#EEE'

        if (colorRows !== false)
            color = '#DDD'

        return (
            <React.Fragment>
                <TableRow sx={{borderBottom: 'unset', backgroundColor: color}}>
                    <TableCell sx={{padding: 0}}>
                        <IconButton
                            aria-label="move up"
                            size="small"
                            disabled={index === 0}
                            onClick={() => move(index, 'UP')}
                        >
                            <ArrowUpwardRoundedIcon/>
                        </IconButton>
                        <IconButton
                            aria-label="move down"
                            size="small"
                            disabled={index === 0 && length === 1 || index === length - 1}
                            onClick={() => move(index, 'DOWN')}
                        >
                            <ArrowDownwardRoundedIcon/>
                        </IconButton>
                    </TableCell>
                    <TableCell
                        sx={{
                            fontFamily: BOLD_FONT,
                            paddingLeft: 0,
                            paddingRight: 0,
                            paddingTop: 1,
                            paddingBottom: 1,
                            minWidth: '40px'
                        }}>
                        {row.name}
                    </TableCell>
                    <TableCell align="right"
                               sx={{paddingLeft: 0, paddingRight: 0, paddingTop: 1, paddingBottom: 1}}><Avatar
                        src={row.photo} sx={{width: 50, height: 50}}></Avatar></TableCell>
                    <TableCell align="left" sx={{
                        fontFamily: 'inherit',
                        padding: 0,
                        minWidth: '40px'
                    }}>{row.service.charAt(0).toUpperCase() + row.service.slice(1)}</TableCell>
                    <TableCell sx={{padding: 0}}>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenReview(existingItems => {
                                return [
                                    ...existingItems.slice(0, index),
                                    !existingItems[index],
                                    ...existingItems.slice(index + 1),
                                ]
                            })}
                        >
                            {openReview[index] ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                        </IconButton>
                    </TableCell>
                    <TableCell sx={{paddingLeft: 0, paddingRight: 0}}>
                        <DeleteOutlinedIcon style={{cursor: 'pointer'}} onClick={() => eliminateReview(index)}
                                            sx={{color: 'darkred'}}/>
                    </TableCell>
                </TableRow>
                <TableRow sx={{backgroundColor: color}}>
                    <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                        <Collapse in={openReview[index]} timeout="auto">
                            <TableRow>
                                <TableCell sx={{paddingTop: 0, paddingBottom: 0}}>
                                    <h4 style={{marginBottom: 0}}>{row.title}</h4>
                                </TableCell>
                                <TableCell sx={{padding: 0, margin: 0}}>
                                    <Button onClick={() => editReview(index)} sx={{color: 'chocolate'}}
                                            variant={'filled'}><EditOutlinedIcon/></Button>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{paddingTop: 0}} colSpan={2}>
                                    <p>{row.body}</p>
                                </TableCell>
                            </TableRow>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        )
            ;
    }

    useEffect(() => {
        getReviews()
    }, [])

    let colorRows = false;


    return (
        <React.Fragment>
            <h1 style={{marginLeft: '5px'}}>Gestione recensioni</h1>
            <TableContainer style={{overflowX: 'clip'}}>
                <Table aria-label="collapsible table">
                    <TableHead sx={{fontFamily: 'inherit'}}>
                        <TableRow>
                            <TableCell/>
                            <TableCell sx={{
                                fontFamily: BOLD_FONT,
                                paddingLeft: 0,
                                paddingRight: 0,
                                paddingTop: 1,
                                paddingBottom: 1
                            }}>NOME CANE</TableCell>
                            <TableCell/>
                            <TableCell sx={{
                                fontFamily: BOLD_FONT,
                                paddingLeft: 0,
                                paddingRight: 0,
                                paddingTop: 1,
                                paddingBottom: 1
                            }} align="left">SERVIZIO</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {arrayReviews.map((row, index) => {
                            colorRows = !colorRows
                            return Row(row, index, colorRows, arrayReviews.length)
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <Stack direction={'row'} width={'100%'} marginTop={2} paddingBottom={10} alignSelf={'center'}
                   justifyContent="space-evenly">
                <Button variant={'contained'} disabled={!modifiedChecker} sx={{
                    fontFamily: 'inherit',
                    backgroundColor: 'orange',
                    '&:hover': {backgroundColor: 'chocolate'}
                }} onClick={() => redo()}>Ripristina</Button>
                <Button variant={'contained'} onClick={() => changeOrderReviews()} sx={{
                    fontFamily: 'inherit',
                    backgroundColor: 'green',
                    '&:hover': {backgroundColor: 'darkgreen'}
                }} disabled={!modifiedChecker}>Conferma modifiche</Button>

                <Fab onClick={() => {
                    setEditMode(false);
                    setNewReview([]);
                    setOpenAddReview(true)
                }}
                     sx={{
                         position: 'fixed',
                         bottom: 16,
                         right: 16,
                         color: 'white',
                         backgroundColor: 'green',
                         '&:hover': {backgroundColor: 'darkgreen'}
                     }} aria-label="add">
                    <AddIcon/>
                </Fab>
            </Stack>

            {addReviewBanner()}




        </React.Fragment>
    )
}

import React, {useEffect} from "react";
import Contacts_photo from "../../images/contacts_photo.jpg";
import {Stack} from "@mui/material";
import {Phone, WhatsApp, Email, Instagram} from "@mui/icons-material";
import './Contattami.css'

export default function Contattami(props) {

    useEffect(() => {
        // document.title = 'Contattami'
    }, [])

    return (
        <React.Fragment>
            <div id='MainDivContatti'>

                <h1 id='title_contatti'>
                    {props.data.contacts.title}
                </h1>

                <Stack id='mainContainer' marginTop={0} direction={'row'} justifyContent="space-around"
                       alignItems="center">

                  <img width={'20%'} height={'100%'} style={{minWidth: 250, borderRadius: 40, borderBottomRightRadius: 0}} src={Contacts_photo} alt={'contacts_photo'}/>
                </Stack>

                <Stack direction={'row'} flexWrap={'wrap'} paddingBottom={5}
                       justifyContent="space-around" alignItems="center">

                    <Stack direction={'column'} justifyContent="space-around" minWidth={300} flex={'33%'}>
                        <Stack alignItems="center" >
                            <p style={{fontSize: 25, marginBottom: 0, marginTop: 25}}>{props.data.contacts.phone}</p>
                            <p style={{marginBottom: 10, marginTop: 5}}>+39 333 7032701</p>
                        </Stack>
                        <Stack direction={'row'} justifyContent="center">
                            <a id={'link'} style={{marginRight: 20}} href="https://wa.me/393337032701">
                                <WhatsApp titleAccess={props.data.contacts.phone_hint1} style={{fontSize: 50, color: 'darkgreen'}}/>
                            </a>
                            <a id={'link'} href="tel:393337032701">
                                <Phone titleAccess={props.data.contacts.phone_hint2} style={{fontSize: 50, color: 'darkblue'}}/>
                            </a>
                        </Stack>
                    </Stack>
                    <Stack direction={'column'} justifyContent="space-around" minWidth={300} flex={'33%'} alignItems="center">
                        <Stack alignItems="center">
                            <p style={{fontSize: 25, marginBottom: 0, marginTop: 25}}>{props.data.contacts.email}</p>
                            <p style={{marginBottom: 10, marginTop: 5}}>marti.educatrice.cinofila@gmail.com</p>
                        </Stack>
                        <Stack direction={'row'} alignSelf="center">
                            <a id={'link'} href="mailto:marti.educatrice.cinofila@gmail.com">
                                <Email titleAccess={props.data.contacts.email_hint} style={{fontSize: 50, color: '#3E65CF'}}/>
                            </a>
                        </Stack>
                    </Stack>
                    <Stack direction={'column'} justifyContent="space-around" minWidth={300} flex={'33%'} alignItems="center">
                        <Stack alignItems="center">
                            <p style={{fontSize: 25, marginBottom: 0, marginTop: 25}}>{props.data.contacts.instagram}</p>
                            <p style={{marginBottom: 10, marginTop: 5}}>marti.educatrice.cinofila</p>
                        </Stack>
                        <Stack direction={'row'} alignSelf="center">
                            <a id={'link'} href="https://www.instagram.com/marti.educatrice.cinofila/">
                                <Instagram titleAccess={props.data.contacts.instagram_hint} style={{fontSize: 50, color: '#C13584', marginBottom: 0}}/>
                            </a>
                        </Stack>
                    </Stack>
                </Stack>


            </div>

        </React.Fragment>
    )
}

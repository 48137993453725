import React, {useEffect} from "react";
import Navbar from '../navbar/Navbar'
import './PercorsoEdu.css'
import Stack from "@mui/material/Stack";
import {Typography} from "@mui/material";
import Footer from "../footer/Footer";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import edu1 from "../../images/Educazione/edu1.jpg"
import edu2 from "../../images/Educazione/edu2.jpg"
import edu3 from "../../images/Educazione/edu3.jpg"
import edu4 from "../../images/Educazione/edu4.jpg"
import edu5 from "../../images/Educazione/edu5.jpg"
import edu6 from "../../images/Educazione/edu6.jpg"
import edu7 from "../../images/Educazione/edu7.jpg"
import edu8 from "../../images/Educazione/edu8.jpg"
import edu9 from "../../images/Educazione/edu9.jpg"
import edu10 from "../../images/Educazione/edu10.jpg"
import edu11 from "../../images/Educazione/edu11.jpg"
import edu12 from "../../images/Educazione/edu12.jpg"
import educazione_martina1 from "../../images/Educazione/educazione_martina1.jpg";
import educazione_martina2 from "../../images/Educazione/educazione_martina2.jpg";

import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import PendingActionsRoundedIcon from '@mui/icons-material/PendingActionsRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import HomeWorkRoundedIcon from '@mui/icons-material/HomeWorkRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import PetsRoundedIcon from '@mui/icons-material/PetsRounded';
import AccessibilityNewRoundedIcon from '@mui/icons-material/AccessibilityNewRounded';
import DoneOutlineRoundedIcon from '@mui/icons-material/DoneOutlineRounded';
import FactCheckRoundedIcon from '@mui/icons-material/FactCheckRounded';

import {PRIMARY_COLOR, SECONDARY_COLOR} from "../../data/Constants";


import {
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
    Timeline
} from "@mui/lab";


export default function PercorsoEdu(props) {

    const images = [
        {
            original: edu1,
            thumbnail: edu1,
        },
        {
            original: edu2,
            thumbnail: edu2,
        },
        {
            original: edu3,
            thumbnail: edu3,
        },
        {
            original: edu4,
            thumbnail: edu4,
        },
        {
            original: edu5,
            thumbnail: edu5,
        },
        {
            original: edu6,
            thumbnail: edu6,
        },
        {
            original: edu7,
            thumbnail: edu7,
        },
        {
            original: edu8,
            thumbnail: edu8,
        },
        {
            original: edu9,
            thumbnail: edu9,
        },
        {
            original: edu10,
            thumbnail: edu10,
        },
        {
            original: edu11,
            thumbnail: edu11,
        },
        {
            original: edu12,
            thumbnail: edu12,
        }
    ];


    useEffect(() => {
        document.title = 'Educazione'
    }, [])

    function CustomizedTimeline() {
        return (
            <Timeline sx={{display: 'flex', width: '95%', mb: 5, left: '0', position: 'relative'}}>
                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{m: 'auto 0', p: 0, pr: 2}}
                        align="right"
                        flex='1'
                        variant="body1"
                        color="grey"
                        fontFamily={'inherit'}
                    >
                        {props.data.training.phase1}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector
                            sx={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20, bgcolor: 'white'}}/>
                        <TimelineDot sx={{bgcolor: PRIMARY_COLOR}}>
                            <SchoolRoundedIcon/>
                        </TimelineDot>
                        <TimelineConnector
                            sx={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}/>
                    </TimelineSeparator>
                    <TimelineContent sx={{py: '2px', flex: '3', px: 2, fontFamily: 'inherit'}}>
                        <Typography sx={{top: 4}} variant="h6" fontFamily={'inherit'} component="span">
                            {props.data.training.phase_title1}
                        </Typography>
                        <Typography fontFamily={'inherit'}>{props.data.training.phase_subtitle1}</Typography>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{m: 'auto 0', p: 0, pr: 2}}
                        align="right"
                        flex='1'
                        variant="body1"
                        color="grey"
                        fontFamily={'inherit'}
                    >

                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector sx={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}/>
                        <TimelineDot sx={{bgcolor: SECONDARY_COLOR}}>
                            <PriorityHighRoundedIcon/>
                        </TimelineDot>
                        <TimelineConnector sx={{borderTopLeftRadius: 20, borderTopRightRadius: 20}}/>
                    </TimelineSeparator>
                    <TimelineContent sx={{py: '12px', flex: '3', px: 2, fontFamily: 'inherit'}}>
                        <Typography variant="h6" fontFamily={'inherit'} component="span">
                            {props.data.training.phase_title2}
                        </Typography>
                        <Typography fontFamily={'inherit'}>{props.data.training.phase_subtitle2}</Typography>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{m: 'auto 0', p: 0, pr: 2}}
                        variant="body1"
                        flex='1'
                        color="text.secondary"
                        fontFamily={'inherit'}
                    >
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector sx={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}/>
                        <TimelineDot sx={{bgcolor: PRIMARY_COLOR}}>
                            <EventNoteRoundedIcon/>
                        </TimelineDot>
                        <TimelineConnector sx={{borderTopLeftRadius: 20, borderTopRightRadius: 20}}/>
                    </TimelineSeparator>
                    <TimelineContent sx={{py: '15px', flex: '3', fontFamily: 'inherit', px: 2}}>
                        <Typography variant="h6" fontFamily={'inherit'} component="span">
                            {props.data.training.phase_title3}
                        </Typography>
                        <Typography fontFamily={'inherit'}>{props.data.training.phase_subtitle3}</Typography>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem position='rigth'>
                    <TimelineOppositeContent
                        sx={{m: 'auto 0', p: 0, pr: 2}}
                        variant="body1"
                        flex='1'
                        color="text.secondary"
                        fontFamily={'inherit'}
                    >
                        {props.data.training.phase2}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector sx={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}/>
                        <TimelineDot sx={{bgcolor: PRIMARY_COLOR}}>
                            <PendingActionsRoundedIcon/>
                        </TimelineDot>
                        <TimelineConnector sx={{borderTopLeftRadius: 20, borderTopRightRadius: 20}}/>
                    </TimelineSeparator>
                    <TimelineContent sx={{py: '15px', flex: '3', fontFamily: 'inherit', px: 2}}>
                        <Typography variant="h6" fontFamily={'inherit'} component="span">
                            {props.data.training.phase_title4}
                        </Typography>
                        <Typography fontFamily={'inherit'}>{props.data.training.phase_subtitle4}</Typography>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{m: 'auto 0', p: 0, pr: 2}}
                        align="right"
                        flex='1'
                        variant="body1"
                        color="grey"
                        fontFamily={'inherit'}
                    >
                        {props.data.training.phase3}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector sx={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}/>
                        <TimelineDot sx={{bgcolor: 'green'}}>
                            <SchoolRoundedIcon/>
                        </TimelineDot>
                        <TimelineConnector
                            sx={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20, bgcolor: 'white'}}/>
                    </TimelineSeparator>
                    <TimelineContent sx={{py: '12px', flex: '3', px: 2, fontFamily: 'inherit'}}>
                        <Typography sx={{top: 4}} variant="h6" fontFamily={'inherit'} component="span">
                            {props.data.training.phase_title5}
                        </Typography>
                        <Typography fontFamily={'inherit'}>{props.data.training.phase_subtitle5}</Typography>
                    </TimelineContent>
                </TimelineItem>
            </Timeline>
        );
    }


    return (
        <React.Fragment>
            <Navbar data={props.data} setLanguage={props.setLanguage} getLanguage={props.getLanguage} button={2}/>

            <Typography variant={'h1'} fontFamily={'inherit'} id='title_percorsoedu'>
                {props.data.training.title1}<span style={{fontFamily: 'Confortaa_bold'}}>{props.data.training.title2}</span>
            </Typography>

            <Stack id='MainDiv'>

                <Stack id='mainContainerPercorsoEdu' direction={'column'}>
                    <Typography bgcolor={PRIMARY_COLOR + '55'} sx={{borderRadius: '15px 0px 15px 15px'}} padding={1}
                                fontFamily={'inherit'} variant={'h6'} fontSize={'1.2rem'} align={'center'} marginTop={3}
                                marginBottom={3}>
                        {props.data.training.subtitle1}<span style={{fontFamily: 'Confortaa_bold'}}>{props.data.training.subtitle2}</span>? <br/>
                        {props.data.training.subtitle3}
                        <span style={{fontFamily: 'Confortaa_bold'}}>{props.data.training.subtitle4}</span>?</Typography>

                    <Stack marginBottom={3}>

                        <Stack marginTop={0} direction={'row'} flexWrap={'wrap'} justifyContent={'space-between'}>
                            <Stack marginTop={2} paddingRight={2} minWidth={'200px'} flex={5} direction={'column'}>
                                <Stack direction={'row'}>
                                    <HomeWorkRoundedIcon sx={{fontSize: 25, color: PRIMARY_COLOR}}/>
                                    <Typography
                                        variant={'h5'}
                                        sx={{
                                            fontFamily: 'inherit',
                                            marginLeft: 2,
                                            color: PRIMARY_COLOR
                                        }}>{props.data.training.table_title1}</Typography>
                                </Stack>
                                <Stack>
                                    <Typography sx={{fontFamily: 'inherit', marginLeft: 0}}>{props.data.training.table_subtitle1}</Typography>
                                </Stack>
                            </Stack>
                            <Stack marginTop={2} flex={8} minWidth={'65%'} direction={'column'}>
                                <Stack direction={'row'}>
                                    <AccessTimeRoundedIcon sx={{fontSize: 25, color: PRIMARY_COLOR}}/>
                                    <Typography
                                        variant={'h5'}
                                        sx={{
                                            fontFamily: 'inherit',
                                            color: PRIMARY_COLOR,
                                            marginLeft: 2
                                        }}>{props.data.training.table_title2}</Typography>
                                </Stack>
                                <Stack direction={'row'}>
                                    <Typography sx={{fontFamily: 'inherit', marginLeft: 0}}>{props.data.training.table_subtitle2}</Typography>
                                </Stack>
                            </Stack>
                        </Stack>

                        <Stack marginTop={0} direction={'row'} flexWrap={'wrap'} justifyContent={'space-between'}>
                            <Stack marginTop={2} marginRight={2} minWidth={'200px'} flex={5} direction={'column'}>
                                <Stack direction={'row'}>
                                    <PetsRoundedIcon sx={{fontSize: 25, color: PRIMARY_COLOR}}/>
                                    <Typography
                                        variant={'h5'}
                                        sx={{
                                            fontFamily: 'inherit',
                                            marginLeft: 2,
                                            color: PRIMARY_COLOR
                                        }}>{props.data.training.table_title3}</Typography>
                                </Stack>
                                <Stack direction={'row'}>
                                    <Typography sx={{fontFamily: 'inherit', marginLeft: 0}}>{props.data.training.table_subtitle3}</Typography>
                                </Stack>
                            </Stack>
                            <Stack marginTop={2} flex={8} minWidth={'65%'} direction={'column'}>
                                <Stack direction={'row'}>
                                    <AccessibilityNewRoundedIcon sx={{fontSize: 25, color: PRIMARY_COLOR}}/>
                                    <Typography
                                        variant={'h5'}
                                        sx={{fontFamily: 'inherit', marginLeft: 2, color: PRIMARY_COLOR}}>{props.data.training.table_title4}</Typography>
                                </Stack>
                                <Typography sx={{fontFamily: 'inherit', marginLeft: 0}}>{props.data.training.table_subtitle4}</Typography>
                            </Stack>
                        </Stack>

                        <Stack marginTop={0} direction={'row'} flexWrap={'wrap'} justifyContent={'space-between'}>
                            <Stack marginTop={2} marginRight={2} minWidth={'200px'} flex={5} direction={'column'}>
                                <Stack direction={'row'}>
                                    <DoneOutlineRoundedIcon sx={{fontSize: 25, color: PRIMARY_COLOR}}/>
                                    <Typography
                                        variant={'h5'}
                                        sx={{
                                            fontFamily: 'inherit',
                                            marginLeft: 2,
                                            color: PRIMARY_COLOR
                                        }}>{props.data.training.table_title5}</Typography>
                                </Stack>
                                <Typography sx={{fontFamily: 'inherit', marginLeft: 0}}>{props.data.training.table_subtitle5}.</Typography>
                            </Stack>
                            <Stack marginTop={2} flex={8} minWidth={'65%'} direction={'column'}>
                                <Stack direction={'row'}>
                                    <FactCheckRoundedIcon sx={{fontSize: 25, color: PRIMARY_COLOR}}/>
                                    <Typography
                                        variant={'h5'}
                                        sx={{
                                            fontFamily: 'inherit',
                                            marginLeft: 2,
                                            color: PRIMARY_COLOR
                                        }}>{props.data.training.table_title6}</Typography>
                                </Stack>
                                <Typography sx={{fontFamily: 'inherit', marginLeft: 0}}>
                                    <ul>
                                        <li>{props.data.training.table_subtitle6_1}</li>
                                        <li>{props.data.training.table_subtitle6_2}</li>
                                        <li>{props.data.training.table_subtitle6_3}</li>
                                        <li>{props.data.training.table_subtitle6_4}</li>
                                        <li>{props.data.training.table_subtitle6_5}</li>
                                    </ul>
                                </Typography>
                            </Stack>
                        </Stack>


                    </Stack>

                    <Stack flexWrap={'wrap'} justifyContent={'space-between'} direction={'row'} gap={4}>
                        <Stack flexBasis={'250px'} flexGrow={1} flexShrink={1}>
                            <img style={{
                                flex: 2,
                                minWidth: 250,
                                width: '20%',
                                borderRadius: 40,
                                borderBottomRightRadius: 0,
                                alignSelf: 'center'
                            }} src={educazione_martina1} alt={props.data.services.alt_edu}/>
                        </Stack>
                        <Stack flexBasis={'250px'} flexGrow={1} flexShrink={1}>
                            <img style={{
                                flex: 2,
                                minWidth: 250,
                                width: '20%',
                                borderRadius: 40,
                                borderTopLeftRadius: 0,
                                alignSelf: 'center'
                            }} src={educazione_martina2} alt={props.data.services.alt_edu}/>
                        </Stack>
                    </Stack>

                    <Stack marginTop={3} alignItems={'start'}>
                        <Typography sx={{fontFamily: 'inherit', alignSelf: 'start', fontSize: 25}}>{props.data.training.subtitle5}</Typography>
                        {CustomizedTimeline()}
                    </Stack>

                    <Typography variant={'h5'} fontFamily={'inherit'} align={'center'} marginBottom={3} marginTop={0}>{props.data.training.contact_me}
                    </Typography>


                    <Stack alignSelf={'center'} marginTop={5} direction={'row'}>
                        <ImageGallery showThumbnails={false} autoPlay={true} items={images}/>
                    </Stack>

                    <Typography fontFamily={'Confortaa_bold'} marginTop={5} marginBottom={2}
                                variant={'h5'}>{props.data.training.subtitle6}</Typography>

                    <Stack flexWrap={'wrap'} gap={2} bgcolor={PRIMARY_COLOR + '1A'} /*bgcolor={'#FEF1E8'}*/
                           border={0} paddingTop={0} paddingBottom={0}
                           boxShadow={0} borderColor={'#999'} sx={{borderRadius: '20px 0px 20px 20px'}}
                           direction={'row'}>

                        <Stack flexBasis={'300px'} flexGrow={1} flexShrink={1} direction={'column'}
                               justifyContent="space-around">
                            <Stack margin={2} direction={'column'}>
                                <h3 id={'subtitle_'} align={'left'}>{props.data.training.box_title1}</h3>
                                <p id={'textSection'} align={'left'}>{props.data.training.box_subtitle1}</p>
                            </Stack>
                            <Stack margin={2} direction={'column'}>
                                <h3 id={'subtitle_'} align={'left'}>{props.data.training.box_title2}</h3>
                                <p id={'textSection'} align={'left'}>{props.data.training.box_subtitle2}</p>
                            </Stack>
                            <Stack margin={2} direction={'column'}>
                                <h3 id={'subtitle_'} align={'left'}>{props.data.training.box_title3}</h3>
                                <p id={'textSection'} align={'left'}>{props.data.training.box_subtitle3}</p>
                            </Stack>
                            <Stack margin={2} marginTop={0} direction={'column'}>
                                <h3 id={'subtitle_'} align={'left'}>{props.data.training.box_title4}</h3>
                                <p id={'textSection'} align={'left'}>{props.data.training.box_subtitle4}</p>
                            </Stack>
                        </Stack>

                        <Stack flexBasis={'300px'} flexGrow={1} flexShrink={1} direction={'column'}
                               justifyContent="space-around">

                            <Stack margin={2} direction={'column'}>
                                <h3 id={'subtitle_'} align={'left'}>{props.data.training.box_title5}</h3>
                                <p id={'textSection'} align={'left'}>{props.data.training.box_subtitle5}</p>
                            </Stack>
                            <Stack margin={2} direction={'column'}>
                                <h3 id={'subtitle_'} align={'left'}>{props.data.training.box_title6}</h3>
                                <p id={'textSection'} align={'left'}>{props.data.training.box_subtitle6}</p>
                            </Stack>
                            <Stack margin={2} direction={'column'}>
                                <h3 id={'subtitle_'} align={'left'}>{props.data.training.box_title7}</h3>
                                <p id={'textSection'} align={'left'}>{props.data.training.box_subtitle7_1}<br/>{props.data.training.box_subtitle7_2}</p>
                            </Stack>
                            <Stack margin={2} direction={'column'}>
                                <h3 id={'subtitle_'} align={'left'}>{props.data.training.box_title8}</h3>
                                <p id={'textSection'} align={'left'}>{props.data.training.box_subtitle8}</p>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>

            </Stack>
            <Footer data={props.data}/>
        </React.Fragment>
    )
}

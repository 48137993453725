import React, {useEffect, useState} from "react";
import logo from "../../images/logo.png";
import Stack from '@mui/material/Stack';
import {NavLink} from "react-router-dom";
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import Avatar from "@mui/material/Avatar";
import './Navbar.css';
import MainPageMobile from "./NavbarMobile";
import {SECONDARY_COLOR} from "../../data/Constants";
import {LANGUAGES} from "../../data/Constants";
import {MenuItem} from "@mui/material";
import { SxProps } from '@mui/material/styles';
import Select from "@mui/material/Select";


const underlinedStyle = {
    backgroundColor: "white",
    color: "black",
}

const notUnderlinedStyle = {
    textDecoration: "none"
}

const menuSelected = {
    backgroundColor: SECONDARY_COLOR,
    color: 'white',
    padding: '10px'
}

const styles: SxProps = {
    select: {
        '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent'
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent'
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent"
        },
        color: 'white',
        fontFamily: 'inherit'
    },
};


export default function Navbar(props) {

    const [isOpenMenu, setIsOpenMenu] = useState(false)
    const [width, setWindowWidth] = useState(0)


    useEffect(() => {

        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () =>
            window.removeEventListener('resize', updateDimensions);
    }, [])


    const updateDimensions = () => {
        const width = window.innerWidth
        setWindowWidth(width)
    }

    function scroll() {
        const element = document.getElementById('footerStack');
        element.scrollIntoView({behavior: 'smooth'})
    }

    function openMenu() {
        setIsOpenMenu(true)
    }

    function closeMenu() {
        setIsOpenMenu(false)
    }

    return (
        <React.Fragment>
            {(width < 930) ?
                <MainPageMobile data={props.data} getLanguage={props.getLanguage} setLanguage={props.setLanguage} button={props.button}/>
                :
                <Stack id='stack' position={'sticky'} direction={'row'}>
                    <Stack direction="row" spacing={1.2} padding={2}>

                        <Avatar
                            alt="Logo"
                            src={logo}
                            id={'avatar_'}
                        />

                        <div>
                            <NavLink id={'HomeButton'} exact to={'/'}>
                                <p id='button'
                                   style={(props.button === 0) ? underlinedStyle : notUnderlinedStyle}>
                                    {props.data.navbar.home}
                                </p>
                            </NavLink>
                        </div>

                        <div onMouseLeave={closeMenu}>
                            <NavLink id={'ServiziButton'} exact to={'/servizi'}>

                                <p id='button' onMouseEnter={openMenu}
                                   style={(props.button === 1 || props.button === 2 || props.button === 3) ? underlinedStyle : notUnderlinedStyle}>
                                    {props.data.navbar.services}
                                </p>
                            </NavLink>

                            <div id={'serviziMenu'}
                                 style={isOpenMenu ? {visibility: 'visible'} : {visibility: 'collapse'}}>
                                <NavLink id={'serviziMenuText'} exact to={'/servizi/percorso_educazione'}>
                                    <li id={'liNav'} style={props.button === 2 ? menuSelected : null}><a
                                        id={'liNav'}>{props.data.navbar.training}</a></li>
                                </NavLink>
                                <NavLink id={'serviziMenuText'} exact to={'/servizi/dog_sitting'}>
                                    <li id={'liNav'} style={props.button === 3 ? menuSelected : null}><a
                                        id={'liNav'}>{props.data.navbar.dog_sitting}</a></li>
                                </NavLink>
                            </div>
                        </div>

                        <div>
                            <NavLink id={'FormazioneButton'} exact to={'/recensioni'}>
                                <p id='button'
                                   style={(props.button === 4) ? underlinedStyle : notUnderlinedStyle}>
                                    {props.data.navbar.reviews}
                                </p>
                            </NavLink>
                        </div>

                        <div>
                            <NavLink id={'FormazioneButton'} onClick={scroll}>
                                <p id='button'
                                   style={(props.button === 'contattami') ? underlinedStyle : notUnderlinedStyle}>
                                    {props.data.navbar.contacts}
                                </p>
                            </NavLink>
                        </div>
                    </Stack>

                    <Stack marginTop={1} spacing={1} flexGrow={2} alignItems={'end'}>
                        <Select
                            value={props.getLanguage}
                            label="Lingua"
                            sx={styles.select}
                        >
                            {LANGUAGES.map(({code, label}) => (
                                <MenuItem onClick={() => props.setLanguage(code)} value={code}>{label}</MenuItem>
                            ))}
                        </Select>
                    </Stack>

                    <Stack spacing={2} marginLeft={-4} paddingRight={2} paddingTop={2.5} flexGrow={1}
                           alignItems={'end'}>
                        <NavLink id={'ContattamiButton'} title={props.data.navbar.personal_area_hint} exact
                                 to={'/login/AreaPersonale'}>
                            <div style={{alignContent: 'end'}}>
                                <PersonRoundedIcon id='button'></PersonRoundedIcon>
                            </div>
                        </NavLink>
                    </Stack>


                </Stack>
            }
        </React.Fragment>
    )
        ;
}

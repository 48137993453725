import React, {useEffect, useState} from "react";
import {auth, logInWithEmailAndPassword, sendPasswordReset} from "../../../firebase/Firebase";
import "./Login.css";
import '../../navbar/NavbarMobile.css';
import {
    TextField,
    Button,
    Stack,
    Backdrop,
    CircularProgress,
    Checkbox,
    FormGroup,
    FormControlLabel
} from '@mui/material';
import {NavLink, useNavigate} from "react-router-dom";
export default function Login({setTypeToast, setTextToast, openToast}) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [user, setUser] = useState(null);
    const [confermResetPass, setConfermResetPass] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [resetPass, setResetPass] = useState(false);

    let navigate = useNavigate();

    useEffect(() => {
        document.title = 'Login'
    }, [])

    function resetPassword() {
        if (confermResetPass && email !== '') {
            sendPasswordReset(email).then((response) => {
                if (response.code === 0)
                    toast('success', 'Link inviato a ' + email);
                else
                    toast('error', response.text);
                setEmail("")
            }).catch(error => toast('error', error.message))
            setConfermResetPass(false)
            setResetPass(false)
        } else
            toast('warning', 'Inserisci una mail valida')
    }

    useEffect(() => {
        setSpinner(true)
        auth.onAuthStateChanged((user) => {
            if (user) {
                setSpinner(false)
                setUser(user)
            }
            setSpinner(false)
        })

    }, [user])

    function toast(code, text) {
        openToast(true)
        setTypeToast(code)
        setTextToast(text)
    }


    return (
        user === null ? (
                <React.Fragment>
                    <Stack id='navbarBox' position={'fixed'} direction="row" justifyContent="center"
                           alignItems="center" >

                        <div id='navTextContainer'>
                            <NavLink style={{color: 'white'}} exact to={'/'}>
                                <h2 id='nameMobile'>
                                    Martina Curcio
                                </h2>
                                <h4 id='jobTitleMobile'>
                                    Educatrice cinofila
                                </h4>
                            </NavLink>
                        </div>
                    </Stack>
                    {!spinner ?
                        (!resetPass ?
                                // standard login interface
                                <React.Fragment>
                                    <Stack height={'100vh'} hidden={spinner} display={'flex'} alignItems={'center'}
                                           justifyContent={'center'} id="login__container">
                                        <Stack direction={'column'} marginTop={10} paddingLeft={5} paddingRight={5} paddingBottom={5}
                                               boxShadow={0} justifySelf={'center'} maxWidth={350}
                                               style={{backgroundColor: 'white'}}>
                                            <p style={{fontSize: 30}}>Accedi alla tua area personale</p>
                                            <TextField variant="outlined"
                                                       sx={{backgroundColor: 'white', fontFamily: 'inherit', borderRadius: 1, marginBottom: 2}}
                                                       type="email"
                                                       color={"secondary"}
                                                       className="login__textBox"
                                                       value={email}
                                                       onChange={(e) => setEmail(e.target.value)}
                                                       placeholder="Inserisci la tua email"
                                            />
                                            <TextField
                                                type="password"
                                                variant="outlined"
                                                className="login__textBox"
                                                color={"secondary"}
                                                sx={{backgroundColor: 'white', fontFamily: 'inherit', borderRadius: 1, marginBottom: 4}}
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                placeholder="Inserisci la tua password"
                                            />
                                            <Button variant="contained"
                                                    disabled={(email === "" && password === "")}
                                                    className="login__btn"

                                                    style={(email === "" && password === "") ? {fontFamily: 'inherit'} : {backgroundColor: 'green', fontFamily: 'inherit'}}
                                                    onClick={() => {
                                                        logInWithEmailAndPassword(email, password).then(response => toast('success', response.text)).catch(error => toast('error', error.message))
                                                    }
                                                    }
                                            >
                                                Login
                                            </Button>

                                            <div>
                                                <p align={'left'} style={{cursor: 'pointer'}}
                                                   onClick={() => (setResetPass(true), setEmail(""))}>Ho dimenticato la password!</p>
                                            </div>

                                        </Stack>
                                    </Stack>
                                </React.Fragment>
                                :
                                // reset password interface
                                <Stack height={'100vh'} hidden={spinner} display={'flex'} alignItems={'center'}
                                       justifyContent={'center'} id="login__container">
                                    <Stack direction={'column'} paddingLeft={5} paddingRight={5} paddingBottom={5}
                                           boxShadow={0} justifySelf={'center'} marginTop={10} maxWidth={350}
                                           style={{backgroundColor: 'white'}}>
                                        <p style={{fontSize: 30}}>Recupera la password</p>
                                        <TextField variant="outlined"
                                                   sx={{backgroundColor: 'white', borderRadius: 1, marginBottom: 2}}
                                                   type="email"
                                                   color={"secondary"}
                                                   className="login__textBox"
                                                   value={email}
                                                   onChange={(e) => setEmail(e.target.value)}
                                                   placeholder="Inserisci la tua email"
                                        />
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox
                                                onChange={() => setConfermResetPass(!confermResetPass)}/>}
                                                              label="Selezionami per confermare"/>
                                        </FormGroup>
                                        <Button variant="contained"
                                                className="login__btn"
                                                onClick={resetPassword}
                                                disabled={!confermResetPass}
                                                style={{pointerEvents: 'auto', fontFamily: 'inherit'}}
                                                title={confermResetPass ? null : 'Seleziona la spunta sopra per proseguire'}
                                        >
                                            Invia link di recupero password
                                        </Button>

                                        <div>
                                            <p align={'left'} style={{cursor: 'pointer'}}
                                               onClick={() => setResetPass(false)}>Torna alla schermata di accesso</p>
                                        </div>

                                    </Stack>
                                </Stack>
                        )
                        : null
                    }

                    <Backdrop
                        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                        open={spinner}
                    >
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                </React.Fragment>)
            :
            navigate('/AreaPersonale')
    );
}

import {initializeApp} from "firebase/app";
import {
    createUserWithEmailAndPassword,
    getAuth,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut,
    updateProfile
} from "firebase/auth";
import { getFirestore, collection, doc, setDoc, getDoc, addDoc } from 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyCdQAeC09rAN8e0sbVpSdS0rU2zPj6hmfE",
    authDomain: "webappmartina.firebaseapp.com",
    projectId: "webappmartina",
    storageBucket: "webappmartina.appspot.com",
    messagingSenderId: "23072307114",
    appId: "1:23072307114:web:6e7ba45be4a6b360a4ddd3",
    measurementId: "G-SJ2JN674Q0"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const getDocument = async (path, ID_document) => {
    try {
        const document = await getDoc(doc(db, path, ID_document))
        if (document.exists())
            return document
    } catch (err) {
        return err
    }
}

const logInWithEmailAndPassword = async (email, password) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
        return {code: 0, text: "Login effettuato!"};
    } catch (err) {
        return {code: 1, text: err.message}
    }
};
const registerWithEmailAndPassword = async (name, email, password) => {
    try {
        const res = await createUserWithEmailAndPassword(auth, email, password);
        const user = res.user;
        await addDoc(collection(db, "users"), {
            uid: user.uid,
            name,
            authProvider: "local",
            email,
        });
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};
const sendPasswordReset = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
        return {code: 0, text: "Password reset link sent!"};
    } catch (err) {
        return {code: 1, text: err.message}
    }
};
const logout = () => {
    signOut(auth);
    return {code: 0, text: "Logout effettuato!"};
};

// TODO: toast photo updated and errors
const updatePhotoURL = async (user, url) => {
    updateProfile(getAuth().currentUser, {photoURL: url}).catch(error => console.log(error))
}
export {
    app,
    auth,
    db,
    doc,
    setDoc,
    getDocument,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
    getAuth,
    updatePhotoURL
};

import React, {useState} from "react";
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import {NavLink} from "react-router-dom";
import {Button, Tooltip} from "@mui/material";
import {logout} from "../../firebase/Firebase";
import Avatar from "@mui/material/Avatar";
import {PRIMARY_COLOR} from "../../data/Constants";
import './NavbarMobile.css';


const drawerWidth = '240px';

export default function NavbarPersonalArea({changeDrawerState, toast, userData, changeAvatar, componentRender}) {
    const [drawerOpen, setDrawerOpen] = useState(false)

    function handleDrawerToggle() {
        setDrawerOpen(!drawerOpen)
        changeDrawerState(!drawerOpen)
    }

    function renderPageBlock(index) {
        componentRender(index)
        if (window.innerWidth < 750 || window.innerWidth / window.innerHeight < 1.5)
            setDrawerOpen(false)
    }

    const drawer = (
        <div style={{backgroundColor: PRIMARY_COLOR, height: '100%'}}>
            <IconButton
                aria-label="open drawer"
                edge="start"
                style={{color: 'white'}}
                onClick={handleDrawerToggle}
                id='buttonMenu'
            >
                <MenuOpenIcon/>
            </IconButton>

            <List style={{backgroundColor: PRIMARY_COLOR}} id={'navbarMobileButton'}>
                {['Homepage', 'Gestione recensioni'].map((text, index) => (
                    <ListItem sx={{fontFamily: 'inherit'}} key={text} disablePadding>
                        <ListItemButton id={'navbarMobileButton'} onClick={() => renderPageBlock(index)}>
                            <ListItemText disableTypography
                                          sx={{color: 'white', fontSize: 15}}
                                          primary={text}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Stack alignItems={'center'} alignContent={'baseline'}>
                <Button variant={'contained'} style={{backgroundColor: 'red', fontFamily: 'inherit'}} onClick={() => {
                    logout();
                    toast('success', 'Logout effettuato!')
                }}>
                    Esci
                </Button>
            </Stack>
        </div>
    );

    return (
        <React.Fragment>
            <Stack id='navbarBox' display={'flex'} position={'relative'} direction="row" spacing={1}
                   justifyContent="space-between"
                   alignItems="center">

                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    id='buttonMenu'
                >
                    <MenuIcon/>
                </IconButton>

                <div id='navTextContainer'>
                    <NavLink style={{color: 'white'}} exact to={'/'}>
                        <h2 id='nameMobile'>
                            Martina Curcio
                        </h2>
                        <h4 id='jobTitleMobile'>
                            Educatrice cinofila
                        </h4>
                    </NavLink>
                </div>
                <Stack direction={'column'} margin={10} >
                    <Tooltip placement={'bottom'} title="Clicca per cambiare avatar" arrow>
                        <Avatar
                            src={userData.photo !== null ? userData.photo : null}
                            sx={{width: 60, height: 60, cursor: 'pointer', marginTop: 0, marginRight: 2, borderWidth: 0}}
                            onClick={() => changeAvatar()}>
                            {//<FontAwesomeIcon color={'black'} icon={faCamera}/>
                            }
                            {userData.name[0] + userData.surname[0]}
                        </Avatar>
                    </Tooltip>
                </Stack>
            </Stack>

            <Drawer
                variant={(window.innerWidth < 750 || window.innerWidth / window.innerHeight < 1.5) ? "temporary" : "persistent"}
                open={drawerOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: {sm: 'block'},
                    '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    fontFamily: 'inherit',
                }}
            >
                {drawer}
            </Drawer>
        </React.Fragment>
    )

}

import React, {useState, useEffect} from "react";
import './homepage.css'
import Keyboard from 'react-mk';
import HomeImage from '../../images/home.jpg'
import {Stack} from "@mui/material";
import Avatar from "@mui/material/Avatar";


export default function TitleHomepage(props) {

    const [count, setCount] = useState(1);

    useEffect(() => {
        setCount(1);
    }, [count]);

    return (
        <div id='firstBlock'>
            <Stack direction={'row'} alignItems={'center'} display={'flex'} flexWrap={'wrap'}
                   justifyContent="space-around"
                   id='firstBlock'>

                <Stack direction={'column'} flexGrow={1} flex={1} minWidth={250}>
                    <Stack direction={'row'}>
                        <h1 id='name'>
                            {props.data.homepage.name}
                        </h1>
                    </Stack>

                    <Stack direction={'row'} minHeight={60}>
                        <div id='jobTitle'>
                            {count ? (
                                <>
                                    <Stack hidden sx={{display: (props.getLanguage === 'en' ? 'none' : '')}}>
                                        <Keyboard sentenceDelayPerCharRange={[0, 100]}>
                                            {({type}) =>
                                                type(
                                                    300,
                                                    'Educatrice Cinofila Professionista',
                                                    300,
                                                    'Dog Sitter Qualificata',
                                                )
                                            }</Keyboard>
                                    </Stack>
                                    <Stack sx={{display: (props.getLanguage === 'it' ? 'none' : '')}}>
                                        <Keyboard sentenceDelayPerCharRange={[0, 100]}>
                                            {({type}) =>
                                                type(
                                                    300,
                                                    'Professional Dog Trainer',
                                                    300,
                                                    'Qualified Dog Sitter',
                                                )
                                            }</Keyboard>
                                    </Stack>
                                </>
                            ) : (
                                ""
                            )}
                        </div>
                    </Stack>


                </Stack>

                <Stack id='homeImage' direction={'column'} alignItems={'center'}>
                    <Avatar rel={"preload"} alt={'Immagine profilo'} sx={{height: '90%', width: '90%'}} src={HomeImage}></Avatar>
                </Stack>

            </Stack>

        </div>
    );
}

import React, {useEffect, useState} from "react";
import Navbar from "../navbar/Navbar"
import '../servizi/PercorsoEdu.css'
import '../formazione/Formazione.css'
import {Stack, Typography} from "@mui/material";
import Footer from "../footer/Footer";
import Avatar from "@mui/material/Avatar";
import {TaskAlt} from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import {db, doc} from "../../firebase/Firebase";
import {getDoc} from "firebase/firestore";
import {PRIMARY_COLOR} from "../../data/Constants";

let position = 'left'


export function Review(review, index, arrayReviews, props) {
    return (
        <React.Fragment>
            <Stack direction={'row'} marginTop={5}>
                <Stack direction={'column'} marginTop={1} marginBottom={1}>
                    <Stack flexWrap={'wrap'} direction={position === "left" ? 'row' : 'row-reverse'}
                           justifyContent="space-around">
                        <Stack flex={'20%'} direction={'column'}
                               alignItems={position === "left" ? 'flex-start' : 'flex-end'}>

                            <Avatar
                                src={(review.photo)}
                                sx={{
                                    maxWidth: 200,
                                    minWidth: 100,
                                    width: '22vw',
                                    maxHeight: 200,
                                    minHeight: 100,
                                    height: '22vw',
                                    marginBottom: 0,
                                    marginRight: 3
                                }}>
                                {review.name.charAt(0).toUpperCase()}
                            </Avatar>

                        </Stack>
                        <Stack flex={'70%'} direction={'column'}>
                            <div>
                                <p id={'dogName'} align={'center'}>{review.name}</p>
                            </div>
                            <p id={'textStory'} style={{textAlign: 'left', minWidth: 300}}>
                                {review.body}
                                <Stack alignItems={position === "left" ? 'flex-start' : 'flex-end'}>
                                    <Stack
                                        title={review.service === "educazione" ? props.training_hint : props.dog_sit_hint}
                                        direction={'row'}
                                        width={136} height={26} borderRadius={10} bgcolor={'#0067002A'}
                                        marginTop={1} padding={1}>
                                        <TaskAlt style={{color: '#006700'}}/>
                                        <p style={{
                                            marginTop: 2,
                                            marginLeft: 5,
                                            color: '#006700'
                                        }}> {review.service === "educazione" ? props.training : props.dog_sit }</p>
                                    </Stack>
                                </Stack>
                            </p>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            {index !== (arrayReviews.length - 1) ? <Divider/> : null }
        </React.Fragment>
    )

}

export default function Recensioni(props) {

    const [arrayReviews, setArrayReviews] = useState([]);

    async function getReviews() {
        const userRef = doc(db, 'reviews', 'reviews_array')
        const docu = await getDoc(userRef)
        setArrayReviews(docu.data().reviews)
    }

    useEffect(() => {
        document.title = 'Recensioni'
        getReviews()
    }, [])

    return (
        <React.Fragment>
            <Navbar  data={props.data} setLanguage={props.setLanguage} getLanguage={props.getLanguage} button={4}/>
            <div id='MainDiv'>

                <h1 id={'title_recensioni'}>{props.data.reviews.title}</h1>

                <Stack marginLeft={2} marginRight={2} direction={'column'}>

                    <div>
                        <Typography bgcolor={PRIMARY_COLOR+'55'} sx={{borderRadius: '15px 0px 15px 15px'}} padding={1}
                                    fontFamily={'inherit'} fontSize={18} align={'center'}
                                    marginTop={-1}
                                    marginBottom={5}>{props.data.reviews.subtitle}</Typography>
                    </div>


                    {arrayReviews.map((element, index) => {
                        const rev = Review(element, index, arrayReviews, props.data.reviews)
                        if (position === "left")
                            position = "right"
                        else
                            position = "left"

                        return rev
                    })}

                </Stack>
            </div>
            <Footer data={props.data}/>

        </React.Fragment>

    )
}

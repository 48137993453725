import React, {useState} from "react";
import {Alert, Box, Button, FormControl, MenuItem, Snackbar, Stack, TextField} from "@mui/material";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import {setDoc} from "firebase/firestore";
import {db, doc} from '../../../firebase/Firebase'

export default function FillUserData({user, setUserComplete, openToastParent}) {

    const [openToast, setOpenToast] = React.useState(false);
    const [name, setName] = useState('')
    const [surname, setSurname] = useState('');
    const [sex, setSex] = useState('');

    async function addUserInfo() {
        if (name !== '' && surname !== '' && sex !== '') {
            const data = {
                name: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
                surname: surname.charAt(0).toUpperCase() + surname.slice(1).toLowerCase(),
                sex: sex,
                photo: null
            }
            const userRef = doc(db, 'users', user.uid)
            await setDoc(userRef, data, {merge: true}).catch(error => openToastParent('error', error.message))
            setUserComplete(true)
            openToastParent('success', 'Aggiornamento dati riuscito.')
        }
        else
            setOpenToast(true)

    }

    return (
        <React.Fragment>
            <Box padding={2}>
                <h1 align={'center'}>Ci siamo quasi.. Ora ho bisogno di sapere qualcosa in più su di te.</h1>
                <p style={{fontSize: 25}} align={'left'}>Aggiungi i dati mancati qui sotto:</p>
                <Stack direction={'column'} overflow={'hidden'} flexWrap={'wrap'} justifyContent="space-around"
                       alignItems="center" marginBottom={5} paddingLeft={"20%"} paddingRight={"20%"} paddingTop={1}>
                    <FormControl fullWidth>
                        <InputLabel id="sex_select">Genere</InputLabel>
                        <Select
                            value={sex}
                            label="Genere"
                            labelId="sex_select"
                            variant={'standard'}
                            onChange={event => setSex(event.target.value)}
                        >
                            <MenuItem value={'male'}>Maschio</MenuItem>
                            <MenuItem value={'female'}>Femmina</MenuItem>
                            <MenuItem value={'not_said'}>Preferisco non indicarlo</MenuItem>
                        </Select>
                        <TextField id="standard-basic" style={{minWidth: '30%'}}
                                   onChange={event => setName(event.target.value)} label="Nome" variant="standard"/>
                        <TextField id="standard-basic" style={{minWidth: '30%'}}
                                   onChange={event => setSurname(event.target.value)} label="Cognome"
                                   variant="standard"/>
                    </FormControl>
                    <Button variant={'contained'} style={{marginTop: 20}} title={'Inserisci i dati'} onClick={() => addUserInfo()}>Inserisci i dati</Button>
                </Stack>

                <Snackbar open={openToast} autoHideDuration={4000} onClose={() => setOpenToast(false)}>
                        <Alert severity={'error'} variant={'filled'}>Inserisci tutti i dati correttamente</Alert>
                </Snackbar>

            </Box>
        </React.Fragment>
    )
}

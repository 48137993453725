import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Homepage from './homepage/Homepage'
import Servizi from './servizi/Servizi'
import ErrorPage from './errorPage/ErrorPage'
import PercorsoEdu from './servizi/PercorsoEdu'
import CookieConsent from "react-cookie-consent";
import resource from "../data/resource.json"

// Import the functions you need from the SDKs you need
import ReactGA from 'react-ga4';
import Dog_sitting from "./servizi/Dog_sitting";
import Login from "./personal_area/login/Login";
import ScrollToTop from "./utils/ScrollToTop";
import AreaPersonale from "./personal_area/AreaPersonale";
import {Alert, Snackbar} from "@mui/material";
import Recensioni from "./recensioni/Recensioni";


const TRACKING_ID = "G-SJ2JN674Q0"; // YOUR_OWN_TRACKING_ID

function App() {
    const [openToast, setOpenToast] = React.useState(false);
    const [text, setText] = React.useState("");
    const [type, setType] = React.useState("");
    const [enableCookie, setEnableCookie] = React.useState(false);
    const [language, setLanguage] = React.useState('it')
    const [data, setData] = useState(resource.resources.it);

    useEffect(() => {
        setData(resource.resources[language])
    }, [language, setLanguage])


    if(window.location.hostname !== 'localhost' && enableCookie) {
        ReactGA.initialize(TRACKING_ID);
    }

    return (
        <div>
            <Router>
                <ScrollToTop>
                    <Routes>
                        <Route path='/' index element={<Homepage setLanguage={setLanguage} getLanguage={language} data={data}/>}/>
                        <Route path='/homepage' index element={<Homepage setLanguage={setLanguage} getLanguage={language} data={data}/>}/>
                        <Route path='/servizi' element={<Servizi setLanguage={setLanguage} getLanguage={language} data={data}/>}/>
                        <Route path='/servizi/percorso_educazione' element={<PercorsoEdu setLanguage={setLanguage} getLanguage={language} data={data}/>}/>
                        <Route path='/servizi/dog_sitting' element={<Dog_sitting setLanguage={setLanguage} getLanguage={language} data={data}/>}/>
                        <Route path='/recensioni' element={<Recensioni setLanguage={setLanguage} getLanguage={language} data={data}/>}/>
                        <Route path='/login/AreaPersonale' element={<Login setTextToast={setText} setTypeToast={setType}
                                                                           openToast={setOpenToast}/>}/>
                        <Route path='/AreaPersonale'
                               element={<AreaPersonale setTextToast={setText} setTypeToast={setType}
                                                       openToast={setOpenToast}/>}/>
                        <Route path="/*" element={<ErrorPage/>}/>
                    </Routes>
                </ScrollToTop>
            </Router>

            <Snackbar open={openToast} autoHideDuration={4000} onClose={() => setOpenToast(false)}>
                <Alert style={{fontFamily: 'inherit'}} severity={type} variant={'filled'}>{text}</Alert>
            </Snackbar>

            <CookieConsent enableDeclineButton
                           buttonText={'Accetta tutti i cookie'}
                           declineButtonText={'Solo cookie tecnici'}
                           buttonStyle={{backgroundColor:'lightblue', fontSize: 16, height: 50, fontFamily: 'inherit', float: 'right'}}
                           declineButtonStyle={{backgroundColor:'darkred', fontSize: 13, height: 25, fontFamily: 'inherit'}}
                           style={{minHeight: '25vh', backgroundColor: '#3B3646'}}
                           flipButtons={false}
                           onAccept={() => {
                               setEnableCookie(true);
                           }}
                           onDecline={() => {
                               setEnableCookie(false);
                           }}>Questo sito utilizza cookie per migliorare l'esperienza utente. Se vuoi, puoi disabilitare i cookie di analisi.</CookieConsent>

        </div>
    );
}

export default App;

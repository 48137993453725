import React, {useEffect, useRef, useState} from "react";
import {auth, getDocument, getAuth, db} from "../../firebase/Firebase";
import "./login/Login.css";
import {
    Backdrop,
    CircularProgress,
    Stack,
    Alert,
    AlertTitle
} from '@mui/material';
import {getStorage, ref, uploadBytes, getDownloadURL} from "firebase/storage";
import Compressor from 'compressorjs';
import {useNavigate} from "react-router-dom";

import './AreaPersonale.css'
import FillUserData from "./utils/FillUserData";
import {doc, setDoc} from "firebase/firestore";
import NavbarPersonalArea from "../navbar/NavbarPersonalArea";
import GestoreRecensioni from "./GestoreRecensioni";

const drawerWidth = '240px';
export default function AreaPersonale({setTextToast, setTypeToast, openToast}) {

    const [spinner, setSpinner] = useState(false);
    const [componentRender, setComponentRender] = useState(0);
    const [user, setUser] = useState(getAuth().currentUser);
    const [userData, setUserData] = useState({name: '', surname: '', sex: '', photo: null});
    const [userComplete, setUserComplete] = useState(true);
    const [drawerOpen, setDrawerOpen] = useState(false);

    let navigate = useNavigate();

    useEffect(() => {
        document.title = 'Area Personale'
        auth.onAuthStateChanged((user) => {
            if (user === null) {
                navigate('/login/AreaPersonale')
            }
            if (user) {
                setSpinner(false)
                setUser(user)
            }
            setSpinner(false)
        })
    }, [navigate])


    useEffect(() => {
        if (user !== null)
            completeUserCheck()
    }, [user])

    async function completeUserCheck() {

        const userDoc = await getDocument('users', user.uid)

        if (userDoc.exists)
            if (userDoc.data().name != null && userDoc.data().surname != null && userDoc.data().sex != null) {
                setUserData(userDoc.data())
                setUserComplete(true)
            } else
                setUserComplete(false)
    }

    const inputFile = useRef(null)

    function changeDrawerState(state) {
        setDrawerOpen(state)
    }

    function toast(type, text) {
        openToast(true)
        setTextToast(text)
        setTypeToast(type)
    }

    function case_switch() {
        switch (componentRender) {
            case 0:
                return (<p>Home</p>)
            case 1:
                return <GestoreRecensioni toast={toast}/>
            case 2:
                return (<p>Nuova attività</p>)
            case 3:
                return (<p>Aggiorna cliente</p>)
            default:
                return (
                    <Alert severity="error">
                        <AlertTitle>Errore</AlertTitle>
                        Ops, c'è un problema! Prova a ricaricare la pagina
                    </Alert>)
        }
    }

    function changeAvatar() {
        inputFile.current.click();
    }

    const handleChange = (event) => {
        const image = event.target.files[0];

        // Create a root reference
        const storage = getStorage();

        // Create a reference to 'images/mountains.jpg'
        const fileRef = ref(storage, 'private/' + user.uid + "/profileLogo");

        new Compressor(image, {
            quality: 0.8,
            success: (compressedResult) => {
                // compressedResult has the compressed file.
                // Use the compressed file to upload the images to your server.
                uploadBytes(fileRef, compressedResult).then((snapshot) => {
                    console.log('Uploaded a photo!');
                    getDownloadURL(snapshot.ref).then(async (downloadURL) => {
                        const data = {
                            photo: downloadURL
                        }
                        const userRef = doc(db, 'users', user.uid)
                        await setDoc(userRef, data, {merge: true}).catch(error => toast('error', error.message)).then(() => toast('success', 'Immagine aggiornata!'))
                    })
                }).catch(error => toast('error', error.message))
            },
            error(error: Error) {
                toast('error', error.message)
            }
        });
    }

    return (
        user !== null ? (
                userComplete ?
                    <React.Fragment>
                        <input type='file' id='file' ref={inputFile} accept="image/png, image/jpeg"
                               style={{display: 'none'}}
                               onChange={handleChange}/>
                        <Stack direction={'row'} height={'100vh'}>
                            <Stack width={'100vw'} flexGrow={1} height={'100%'}
                                   direction={'column'} alignContent={'space-around'}
                                   alignItems={'stretch'}>
                                <NavbarPersonalArea toast={toast} changeAvatar={changeAvatar} userData={userData}
                                                    componentRender={setComponentRender}
                                                    changeDrawerState={changeDrawerState}/>

                                <Stack direction={'row'} height={'100%'}>
                                    <Stack direction={'column'}
                                           width={(window.innerWidth < 900 || window.innerWidth / window.innerHeight < 1.5) ? null : (drawerOpen ? drawerWidth : '0')}/>
                                    <Stack direction={'column'} flexGrow={1} width={'min-content'}>
                                        <Stack display={(componentRender !== 0) ? 'none' : null} width={'100%'} direction={'row'} alignSelf={'start'}
                                               marginBottom={0}>
                                            {
                                                <p style={{marginBottom: 0}}>Ei {userData.name}, {userData.sex === 'male' ? 'ben tornato' : (userData.sex === 'female' ? 'ben tornata' : 'ben tornat*')}!
                                                    :)</p>
                                            }
                                        </Stack>
                                        {case_switch()}
                                    </Stack>
                                </Stack>

                            </Stack>
                        </Stack>

                        <Backdrop
                            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                            open={spinner}
                        >
                            <CircularProgress color="inherit"/>
                        </Backdrop>
                    </React.Fragment>
                    :

                    // Add user info
                    <React.Fragment>
                        <FillUserData user={user} setUserComplete={setUserComplete} openToastParent={toast}/>
                    </React.Fragment>
            ) :
            // Go back in the login form
            navigate('/login/AreaPersonale')
    );
}

import React, {useEffect} from "react";
import Navbar from '../navbar/Navbar'
import './PercorsoEdu.css'
import Stack from "@mui/material/Stack";
import {Typography} from "@mui/material";
import Footer from "../footer/Footer";
import dogsitting1 from "../../images/Dogsitting/dogsitting1.jpg";
import dogsitting2 from "../../images/Dogsitting/dogsitting2.jpg";
import dogsitting3 from "../../images/Dogsitting/dogsitting3.jpg";
import dogsitting4 from "../../images/Dogsitting/dogsitting4.jpg";
import dogsitting5 from "../../images/Dogsitting/dogsitting5.jpg";
import dogsitting6 from "../../images/Dogsitting/dogsitting6.jpg";
import dogsitting_martina1 from "../../images/Dogsitting/dogsitting_martina1.jpeg";
import dogsitting_martina2 from "../../images/Dogsitting/dogsitting_martina2.jpeg";

import ImageGallery from "react-image-gallery";
import {
    Timeline,
    TimelineConnector, TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from "@mui/lab";
import HailRoundedIcon from '@mui/icons-material/HailRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import DirectionsWalkRoundedIcon from '@mui/icons-material/DirectionsWalkRounded';
import HomeWorkRoundedIcon from "@mui/icons-material/HomeWorkRounded";
import HandshakeRoundedIcon from '@mui/icons-material/HandshakeRounded';
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import AccessibilityNewRoundedIcon from "@mui/icons-material/AccessibilityNewRounded";
import DoneOutlineRoundedIcon from "@mui/icons-material/DoneOutlineRounded";
import GradeRoundedIcon from '@mui/icons-material/GradeRounded';
import {SECONDARY_COLOR} from "../../data/Constants";

export default function Dog_sitting(props) {

    const images = [
        {
            original: dogsitting1,
            thumbnail: dogsitting1,
        },
        {
            original: dogsitting2,
            thumbnail: dogsitting2,
        },
        {
            original: dogsitting3,
            thumbnail: dogsitting3,
        },
        {
            original: dogsitting4,
            thumbnail: dogsitting4,
        },
        {
            original: dogsitting5,
            thumbnail: dogsitting5,
        },
        {
            original: dogsitting6,
            thumbnail: dogsitting6,
        }
    ];


    useEffect(() => {
        document.title = 'Dog Sitting'
    }, [])

    function CustomizedTimeline() {
        return (
            <Timeline sx={{width: '85vw', mb: 5, left: '0', position: 'relative'}}>
                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{m: 'auto 0'}}
                        align="right"
                        variant="body1"
                        color="grey"
                        fontFamily={'inherit'}
                    >
                        {props.data.dog_sit.phase1}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector
                            sx={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20, bgcolor: 'white'}}/>
                        <TimelineDot sx={{bgcolor: SECONDARY_COLOR}}>
                            <HailRoundedIcon/>
                        </TimelineDot>
                        <TimelineConnector
                            sx={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}/>
                    </TimelineSeparator>
                    <TimelineContent sx={{py: '2px', width: '20vw', px: 2, fontFamily: 'inherit'}}>
                        <Typography sx={{top: 4}} variant="h6" fontFamily={'inherit'} component="span">
                            {props.data.dog_sit.phase_title1}
                        </Typography>
                        <Typography fontFamily={'inherit'}>{props.data.dog_sit.phase_subtitle1}</Typography>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineConnector sx={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}/>
                        <TimelineDot sx={{bgcolor: SECONDARY_COLOR}}>
                            <GroupRoundedIcon/>
                        </TimelineDot>
                        <TimelineConnector sx={{borderTopLeftRadius: 20, borderTopRightRadius: 20}}/>
                    </TimelineSeparator>
                    <TimelineContent sx={{py: '12px', width: '20vw', px: 2, fontFamily: 'inherit'}}>
                        <Typography variant="h6" fontFamily={'inherit'} component="span">
                            {props.data.dog_sit.phase_title2}
                        </Typography>
                        <Typography fontFamily={'inherit'}>{props.data.dog_sit.phase_subtitle2}</Typography>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem position='left'>
                    <TimelineOppositeContent
                        sx={{m: 'auto 0'}}
                        variant="body1"
                        color="text.secondary"
                        fontFamily={'inherit'}
                    >
                        {props.data.dog_sit.phase2}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector sx={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}/>
                        <TimelineDot sx={{bgcolor: SECONDARY_COLOR}}>
                            <DirectionsWalkRoundedIcon/>
                        </TimelineDot>
                        <TimelineConnector sx={{borderTopLeftRadius: 20, borderTopRightRadius: 20}}/>
                    </TimelineSeparator>
                    <TimelineContent sx={{py: '42px', width: '20vw', fontFamily: 'inherit', px: 2}}>
                        <Typography variant="h6" fontFamily={'inherit'} component="span">
                            {props.data.dog_sit.phase_title3}
                        </Typography>
                        <Typography fontFamily={'inherit'}>{props.data.dog_sit.phase_subtitle3}</Typography>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{m: 'auto 0'}}
                        align="right"
                        variant="body1"
                        color="grey"
                        fontFamily={'inherit'}
                    >
                        {props.data.dog_sit.phase3}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector sx={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}/>
                        <TimelineDot sx={{bgcolor: 'green'}}>
                            <HandshakeRoundedIcon/>
                        </TimelineDot>
                        <TimelineConnector
                            sx={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20, bgcolor: 'white'}}/>
                    </TimelineSeparator>
                    <TimelineContent sx={{py: '12px', width: '20vw', px: 2, fontFamily: 'inherit'}}>
                        <Typography sx={{top: 4}} variant="h6" fontFamily={'inherit'} component="span">
                            {props.data.dog_sit.phase_title4}
                        </Typography>
                        <Typography fontFamily={'inherit'}>{props.data.dog_sit.phase_subtitle4}</Typography>
                    </TimelineContent>
                </TimelineItem>
            </Timeline>
        );
    }


    return (
        <React.Fragment>
            <Navbar data={props.data} setLanguage={props.setLanguage} getLanguage={props.getLanguage} button={3}/>

            <Typography variant={'h1'} fontFamily={'inherit'} id='title_dogsitting'>
                {props.data.dog_sit.title1}<span style={{fontFamily: 'Confortaa_bold'}}>{props.data.dog_sit.title2}</span>
            </Typography>

            <div id='MainDiv'>

                <Stack id='mainContainerDogsitting' direction={'column'}>
                    <Typography bgcolor={SECONDARY_COLOR+'55'} sx={{borderRadius: '15px 0px 15px 15px'}} padding={1}
                                fontFamily={'inherit'} variant={'h6'} fontSize={'1.2rem'} align={'center'}
                                marginBottom={0}>{props.data.dog_sit.banner1}</Typography>
                    <Typography variant={'h5'} fontSize={'1.1rem'} fontFamily={'inherit'} textAlign={'left'}
                                marginBottom={3} marginTop={0}><br/>
                        <span style={{fontFamily: 'Confortaa_bold'}}>{props.data.dog_sit.subtitle1}</span>{props.data.dog_sit.subtitle2}<span
                            style={{fontFamily: 'Confortaa_bold'}}>{props.data.dog_sit.subtitle3}</span>{props.data.dog_sit.subtitle4}<span style={{fontFamily: 'Confortaa_bold'}}>
                            {props.data.dog_sit.subtitle5}</span>
                        {props.data.dog_sit.subtitle6}</Typography>

                    <Stack marginBottom={5}>

                        <Stack marginTop={0} direction={'row'} flexWrap={'wrap'} justifyContent={'space-between'}>
                            <Stack marginTop={2} paddingRight={2} minWidth={'200px'} flex={5} direction={'column'}>
                                <Stack direction={'row'}>
                                    <HomeWorkRoundedIcon sx={{fontSize: 25, color: SECONDARY_COLOR}}/>
                                    <Typography
                                        variant={'h5'}
                                        sx={{
                                            fontFamily: 'inherit',
                                            marginLeft: 2,
                                            color: SECONDARY_COLOR
                                        }}>{props.data.dog_sit.table_title1}</Typography>
                                </Stack>
                                <Stack>
                                    <Typography sx={{fontFamily: 'inherit', marginLeft: 0}}>{props.data.dog_sit.table_subtitle1}</Typography>
                                </Stack>
                            </Stack>
                            <Stack marginTop={2} flex={8} minWidth={'65%'} direction={'column'}>
                                <Stack direction={'row'}>
                                    <AccessTimeRoundedIcon sx={{fontSize: 25, color: SECONDARY_COLOR}}/>
                                    <Typography
                                        variant={'h5'}
                                        sx={{
                                            fontFamily: 'inherit',
                                            color: SECONDARY_COLOR,
                                            marginLeft: 2
                                        }}>{props.data.dog_sit.table_title2}</Typography>
                                </Stack>
                                <Stack direction={'row'}>
                                    <Typography sx={{fontFamily: 'inherit', marginLeft: 0}}>{props.data.dog_sit.table_subtitle2_1}
                                        <br/>{props.data.dog_sit.table_subtitle2_2}</Typography>
                                </Stack>
                            </Stack>
                        </Stack>

                        <Stack marginTop={0} direction={'row'} flexWrap={'wrap'} justifyContent={'space-between'}>
                            <Stack marginTop={2} marginRight={2} minWidth={'200px'} flex={5} direction={'column'}>
                                <Stack direction={'row'}>
                                    <DoneOutlineRoundedIcon sx={{fontSize: 25, color: SECONDARY_COLOR}}/>
                                    <Typography
                                        variant={'h5'}
                                        sx={{
                                            fontFamily: 'inherit',
                                            marginLeft: 2,
                                            color: SECONDARY_COLOR
                                        }}>{props.data.dog_sit.table_title3}</Typography>
                                </Stack>
                                <Typography sx={{fontFamily: 'inherit', marginLeft: 0}}>{props.data.dog_sit.table_subtitle3}</Typography>
                            </Stack>
                            <Stack marginTop={2} flex={8} minWidth={'65%'} direction={'column'}>
                                <Stack direction={'row'}>
                                    <AccessibilityNewRoundedIcon sx={{fontSize: 25, color: SECONDARY_COLOR}}/>
                                    <Typography
                                        variant={'h5'}
                                        sx={{fontFamily: 'inherit', marginLeft: 2, color: SECONDARY_COLOR}}>{props.data.dog_sit.table_title4}</Typography>
                                </Stack>
                                <Typography sx={{fontFamily: 'inherit', marginLeft: 0}}>{props.data.dog_sit.table_subtitle4}</Typography>
                            </Stack>
                        </Stack>

                        <Stack marginTop={0} direction={'row'} flexWrap={'wrap'} justifyContent={'space-between'}>
                            <Stack marginTop={2} flex={8} minWidth={'60%'} direction={'column'}>
                                <Stack direction={'row'}>
                                    <GradeRoundedIcon sx={{fontSize: 25, color: SECONDARY_COLOR}}/>
                                    <Typography
                                        variant={'h5'}
                                        sx={{
                                            fontFamily: 'inherit',
                                            marginLeft: 2,
                                            color: SECONDARY_COLOR
                                        }}>{props.data.dog_sit.table_title5}</Typography>
                                </Stack>
                                <Typography sx={{fontFamily: 'inherit', marginLeft: 0}}>{props.data.dog_sit.table_subtitle5}</Typography>
                            </Stack>
                        </Stack>


                    </Stack>

                    <Stack flexWrap={'wrap'} justifyContent={'space-between'} direction={'row'} gap={4}>
                        <Stack flexBasis={'250px'} flexGrow={1} flexShrink={1}>
                            <img style={{
                                flex: 2,
                                minWidth: 250,
                                width: '20%',
                                borderRadius: 40,
                                borderBottomRightRadius: 0,
                                alignSelf: 'center'
                            }} src={dogsitting_martina1} alt={'dogsitting_martina'}/>
                        </Stack>
                        <Stack flexBasis={'250px'} flexGrow={1} flexShrink={1}>
                            <img style={{
                                flex: 2,
                                minWidth: 250,
                                width: '20%',
                                borderRadius: 40,
                                borderTopLeftRadius: 0,
                                alignSelf: 'center'
                            }} src={dogsitting_martina2} alt={'dogsitting_martina'}/>
                        </Stack>
                    </Stack>

                    <Stack marginTop={3} alignItems={'start'}>
                        <Typography variant={'h5'} sx={{fontFamily: 'inherit', alignSelf: 'start'}}>{props.data.dog_sit.subtitle7}</Typography>
                        {CustomizedTimeline()}
                    </Stack>

                    <Typography variant={'h5'}  fontFamily={'inherit'} align={'center'} marginBottom={3} marginTop={0}>{props.data.dog_sit.contact_me}
                    </Typography>

                    <Stack alignSelf={'center'} direction={'row'}>
                        <ImageGallery showThumbnails={false} autoPlay={true} items={images}/>
                    </Stack>


                    <Typography fontFamily={'Confortaa_bold'} marginTop={5} marginBottom={2}
                                variant={'h5'} >{props.data.dog_sit.subtitle8}</Typography>
                    <Stack direction={'column'} bgcolor={SECONDARY_COLOR+'1A'} gap={2} boxShadow={0} borderColor={'#999'} marginTop={0}
                           border={0}
                           sx={{borderRadius: '20px 0px 20px 20px'}}>
                        <Stack flexBasis={'300px'} flexGrow={1} flexShrink={1} direction={'column'} justifyContent="space-around">
                            <Stack margin={2} direction={'column'}>
                                <h3 id={'subtitle_'} align={'left'}>{props.data.dog_sit.box_title1}</h3>
                                <p id={'textSection'} align={'left'}>{props.data.dog_sit.box_subtitle1}</p>
                            </Stack>
                            <Stack margin={2} direction={'column'}>
                                <h3 id={'subtitle_'} align={'left'}>{props.data.dog_sit.box_title2}</h3>
                                <p id={'textSection'} align={'left'}>{props.data.dog_sit.box_subtitle2}</p>
                            </Stack>
                            <Stack margin={2} direction={'column'}>
                                <h3 id={'subtitle_'} align={'left'}>{props.data.dog_sit.box_title3}</h3>
                                <p id={'textSection'} align={'left'}>{props.data.dog_sit.box_subtitle3}</p>
                            </Stack>
                        </Stack>

                    </Stack>

                </Stack>

            </div>
            <Footer data={props.data}/>

        </React.Fragment>
    )
}
